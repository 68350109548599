import React from "react"

import Layout from "components/Layout/de"
import Product from "components/Layout/Product/index"

import productImg from "../../use-cases/videos/rust.mp4"
import productIcon from "./MetalScanner.svg"

import { Table } from "react-bootstrap"
import { MdDone, MdCancel } from "react-icons/md"

import image1 from "./rust_1.jpg"
import image2 from "./metal_scanner_0.jpg"
import image3 from "../../use-cases/videos/bearing.mp4"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Automatisieren Sie die Unterscheidung von Oberflächendefekten auf Metall
        auf unter eine Sekunde! Die MoonVision Software unterscheidet
        automatisch zwischen verschiedenen Oberflächen und Defektarten und
        leitet diese Information weiter wo sie diese am dringensten benötigen.
      </p>
      <h3>Highlghts</h3>
      <ul>
        <li>Automatisierte Qualitätsprüfung von Metalloberflächen</li>
        <li>
          Von gleichbleibender Qualität und verkürzter Durchlaufzeit profitieren
        </li>
        <li>Erkennung und Messung mittels Kamera & Software</li>
        <li>Höchste Genauigkeit & Komfort durch Künstliche Intelligenz</li>
        <li>Auswertungen an Folgesysteme oder visuelle Darstellung</li>
      </ul>
    </React.Fragment>
  )

  return (
    <Layout location={props.location}>
      <Product
        id="MetalScanner"
        title="Qualitätsprüfung von Metallteilen in Echtzeit"
        subtitle="Automatisierte visuelle Prüfung (Sichtprüfung) von Metallbauteilen"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automatisierte Metallteilprüfung",
          "Gleichbleibend hohe Qualität",
          "Verkürzte Durchlaufzeit",
          "Jetzt Informieren",
        ]}
        images={[image1, image2, image3]}
        technicalTable={
          <Table responsive size="sm">
            <thead>
              <tr>
                <th colSpan="2">Oberflächenprüfung</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>(Haar-) Risse</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Poren</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Fehlstellen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Korrosionserscheinungen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Gitterfehler</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Einschnürungen bei Rohren</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Farbflecken</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Kontrastreiche Fehler (im allgemeinen)</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  Rautiefe R<sub>a</sub> (µm)
                </td>
                <td>
                  <MdDone />
                  <sup>1</sup>
                </td>
              </tr>
              <tr>
                <td>Rautiefen-Unterschiede an einem Bauteil</td>
                <td>
                  <MdDone />
                  <sup>2</sup>
                </td>
              </tr>
              <tr>
                <td>Bearbeitungsart (z.B. Fräsen, Schleifen, Hobeln, usw.)</td>
                <td>
                  <MdDone />
                  <sup>1</sup>
                </td>
              </tr>
              <tr>
                <td>
                  Fehler im Bauteil (z.B. Lunker), welche nicht sichtbar sind
                </td>
                <td>
                  <MdCancel />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <small>
                    [1] 1 gewährleistet in dem Ausmaß, solange ein Experte die
                    Rautiefe oder Bearbeitungsart selbst mit dem Auge bewerten
                    kann.
                    <br />
                    [2] gewährleistet, solange Kontrastunterschiede zwischen den
                    Flächen erkennbar sind.
                  </small>
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Formprüfung</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Außenmaße</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Position von Bohrungen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Position von Bearbeitungskanten</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Konturen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <p>
            Der MoonVision MetalScanner wurde speziell für die
            Oberflächenprüfung von Metallteilen entwickelt. Dabei werden neueste
            Technologien wie Machine Learning eingesetzt um auch die kleinsten,
            für Mitarbeiter kaum rasch zu erkennende Abweichungen festzustellen
            und zu klassifizieren. Diese Informationen werden dazu genutzt wie
            mit dem Metallteil weiter verfahren wird, daher ob es für die
            Produktion genutzt werden kann, oder ausgetauscht / gemeldet werden
            müssen etc. Im Anschluss können auch Messungen durchgeführt werden.
            Prüfungen können sicherstellen dass die metallischen Teile geltenden
            Standards entsprechen oder Sie definieren eigene Prüfregeln.
          </p>
          <p>
            Durch die eingesetzte Künstliche Intelligenz werden bestehende
            Systeme im Hinblick auf die Prüfgenauigkeit in den Schatten gestellt
            – der Bewertungsprozess wird vollautomatisiert und Sie sparen
            wertvolle Zeit.
          </p>
          <p>
            Der MoonVision MetalScanner unterstützt sie bei der Prüfung nach
            folgenden Prozessschritten:
          </p>
          <ul>
            <li>Kornstruktur Analysen</li>
            <li>Walz- und Gießprozess</li>
            <li>Spanlose Umformprozesse wie zB. Biegen und Tiefziehen</li>
            <li>Spanende Fertigungstechniken</li>
            <li>
              Oberflächenbehandlung durch Lackieren, Pulverbeschichtung,
              Galvanisieren und PVD
            </li>
            <li>Additive Verfahren wie zB Sintern</li>
          </ul>
          <p>
            Der MoonVision MetalScanner kann an unterschiedlichen
            Produktionsschritten von der Wareneingangskontrolle bis hin zur
            Warenausgangskontrolle eingesetzt werden. Durch das autarke Design
            kann die Software in einen laufenden Fertigungsprozess bzw.
            vorhandene ERP-Systeme integriert werden.
          </p>
          <p>Nach Ablauf der Kontrolle können die Ergebnisse ausserdem:</p>
          <ul>
            <li>
              Visuell dargestellt werden (Auf einem Monitor/Alarme/Ablage von
              jpg Dateien)
            </li>
            <li>Tabellarisch zusammengefasst werden</li>
            <li>
              Per APIs an Managementinformationssysteme weitergegeben werden
            </li>
            <li>Per APIs zur Prozesssteuerung eingesetzt werden</li>
          </ul>
          <p>Durch das MoonVision Konzept</p>
          <ul>
            <li>
              können individuelle Anpassungen selbstständig durchgeführt werden
            </li>
            <li>
              bleibt der Zugang und Kontrolle über die Erkennungsparameter bei
              ihnen
            </li>
          </ul>
          <h3>Überall einsetzbar</h3>
          <p>
            Der MoonVision MetalScanner kann alle bestehenden Kamerasysteme als
            Datenquellen nutzen – die den Genicam Standard erfüllen, daher auch
            bereits bestehende. Gerne beraten wir Sie auch bei der Wahl des
            richtigen Systems. Die Überprüfung kann in Echtzeit über unsere
            Online-Plattform erfolgen. Alternativ dazu können wir das System
            auch vor Ort installieren. In beiden Fällen erhalten Sie Zugriff zur
            MoonVision Toolbox und können damit jederzeit Anpassungen zB zur
            Parameteradaptierung und Personalisierung durchführen.
          </p>
          <h3>Einfach in der Anwendung</h3>
          <p>
            Aufgrund des Softwaredesigns ist die Optimierung und Implementierung
            des MoonVision MetalScanner mit geringem Aufwand vor Ort bei Ihnen
            realisierbar. Bereits nach der Überprüfung weniger Bild/Video Daten
            herrscht Gewissheit über die Qualität, weitere Bilddaten können auch
            zu einem späteren Zeitpunkt ergänzt werden. Alle Daten werden
            zentral gesammelt, gespeichert und sind jederzeit für Sie abrufbar.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
